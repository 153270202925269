/** @format */

import useSound from "use-sound";
import { settings } from "../../settings";

const doubleKillSoundFile = require(`../../assets/audio/${settings.doubleKillSound}`);
const gunshotSoundFile = require(`../../assets/audio/${settings.gunshotSound}`);

export const useSounds = () => {
  const [playGunShotSound] = useSound(gunshotSoundFile, {
    volume: settings.gunShotSoundVolume,
  });
  const [playDoubleKillSound] = useSound(doubleKillSoundFile, {
    volume: settings.doubleKillSoundVolume,
  });

  return { playGunShotSound, playDoubleKillSound };
};
