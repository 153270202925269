/** @format */

import { settings as settings2 } from "../../../../settings";
import { enemyStates } from "../../constants";
import { generateNewEnemyDirectionXandY, randomMinMax } from "../../utils";

const enemySleepingTrailImage = require(`../../../../assets/images/${settings2.enemySleepingTrailImage}`);

const birdIsAliveAndActiveUpdates = (bird, frame, settings) => {
  const bounces = {
    topBounce: bird.y + 10 < 0 || bird.y < 0 - bird.height,
    leftBounce: bird.x + 10 < 0,
    rightBounce: bird.x - 10 > settings.canvasWidth - bird.width,
    bottomBounce:
      bird.y - 10 > settings.cavnasHeight - settings.canvasGroundLevel,
  };

  if (bird.countdown === true || bounces.bottomBounce) {
    if (bounces.topBounce) {
      const { x, y } = generateNewEnemyDirectionXandY(bird, "top");
      bird.directionY = parseFloat(y);
      bird.directionX = parseFloat(x);
    }
    if (bounces.bottomBounce) {
      const { x, y } = generateNewEnemyDirectionXandY(bird, "bottom");
      bird.directionY = parseFloat(y);
      bird.directionX = parseFloat(x);
    }
    if (bounces.leftBounce) {
      const { x, y } = generateNewEnemyDirectionXandY(bird, "left");
      bird.directionY = parseFloat(y);
      bird.directionX = parseFloat(x);
    }
    if (bounces.rightBounce) {
      const { x, y } = generateNewEnemyDirectionXandY(bird, "right");
      bird.directionY = parseFloat(y);
      bird.directionX = parseFloat(x);
    }
  }

  const stressedBirdSpeedMod =
    settings.stressedEnemyPercentageFaster * 0.01 + 1;
  bird.x += bird.isStressedAsFuck
    ? bird.directionX * stressedBirdSpeedMod
    : bird.directionX;
  bird.y -= bird.isStressedAsFuck
    ? bird.directionY * stressedBirdSpeedMod
    : bird.directionY;

  // birdFrame
  const isFlyingLeft = bird.directionX < 0;
  if (isFlyingLeft && bird.frameY !== 1) {
    bird.frameY = 1;
  }

  if (!isFlyingLeft && bird.frameY !== 0) {
    bird.frameY = 0;
  }

  const flapMultiplier = Math.floor(
    settings.enemyWingFlappingSpeed -
      (Math.abs(bird.directionX) + Math.abs(bird.directionY)) * 1.5
  );

  const flapMultiplier2 = bird.isStressedAsFuck
    ? flapMultiplier - 3
    : flapMultiplier;

  const flappingSpeed =
    flapMultiplier2 < 5 ? frame % 5 === 0 : frame % flapMultiplier2 === 0;

  if (flappingSpeed) {
    const prevFrameX = bird.frameX;
    bird.frameX = prevFrameX < 3 ? prevFrameX + 1 : 0;
  }
};

const birdIsPainStateUpdates = (bird, settings) => {
  if (!bird.painStateDurationState) {
    bird.painStateDurationState = settings.enemyPainStateDuration;
  } else {
    bird.painStateDurationState = bird.painStateDurationState - 1;

    if (bird.painStateDurationState === 0) {
      bird.state = bird.state.filter((s) => s !== enemyStates.PAINSTATE);
    }
  }
  // bird.spriteHeight = 109;
  bird.frameY = 2;
  bird.frameX = 0;
};

const birdIsFlewAwayStateUpdates = (bird) => {
  bird.state = [...bird.state, enemyStates.MISSED];
  bird.dontDraw = true;
};

const birdIsFallingUpdates = (
  bird,
  frame,
  setLastDeadEnemyPositionX,
  settings
) => {
  bird.y += settings.enemyFallingSpeed;

  const reachBottom = bird.y > settings.cavnasHeight;
  if (reachBottom) {
    bird.dontDraw = true;
    setLastDeadEnemyPositionX(bird.x + 15);
  }

  const updateEnemySleepingTrail = () => {
    let newSleepingTrailArray = [];
    if (
      bird.sleepingTrail.length < 10 &&
      frame % settings.enemySleepingTrailGenerationSpeed === 0
    ) {
      const zImage = new Image();
      zImage.src = enemySleepingTrailImage;
      const minX = bird.x;
      const maxX = bird.x + (bird.width / 3) * 2;
      const ZXposition = randomMinMax(minX, maxX);

      const newSleepingTrail = {
        image: zImage,
        xDrawingStart: 0,
        yDrawingStart: 0,
        spriteWidth: 100,
        spriteHeight: 90,
        x: ZXposition,
        y: bird.y,
        width: 100 / 2,
        height: 90 / 2,
      };

      newSleepingTrailArray = [newSleepingTrail, ...bird.sleepingTrail];
    } else {
      newSleepingTrailArray = bird.sleepingTrail;
    }

    let updatedSleepingTrailArray = [];
    for (let i = 0; i < newSleepingTrailArray.length; i++) {
      let currentZ = newSleepingTrailArray[i];
      currentZ = {
        ...currentZ,
        width: currentZ.width < 0 ? currentZ.width : currentZ.width - 1,
        height: currentZ.height < 0 ? currentZ.height : currentZ.height - 1,
      };
      updatedSleepingTrailArray.push(currentZ);
    }
    bird.sleepingTrail = updatedSleepingTrailArray;
  };

  updateEnemySleepingTrail();

  const bFX = () => {
    if (bird.frameY < 3) {
      const wasFlyingLeft = bird.directionX < 0;
      return wasFlyingLeft ? 0 : 1;
    }

    return bird.frameX === 0 ? 1 : 0;
  };

  bird.frameY = 3;

  if (frame % settings.enemyFallingFlickeringSpeed === 0) {
    bird.frameX = bFX();
  }
};

export const updateEnemy = (
  bird,
  canvasRef,
  frame,
  setLastDeadEnemyPositionX,
  bullets,
  settings
) => {
  if (bird.dontDraw) {
    return bird;
  }

  if (bullets === 0) {
    bird.countdown = "RUN";
  }

  if (bird.countdown === false) {
    bird.countdown = true;
    setTimeout(() => {
      bird.countdown = "RUN";
    }, settings.enemyFlyingTime * 1000);
  }

  const isPainState =
    bird.state.includes(enemyStates.SHOT) &&
    bird.state.includes(enemyStates.PAINSTATE);

  const isFallingState =
    bird.state.includes(enemyStates.SHOT) &&
    !bird.state.includes(enemyStates.PAINSTATE);

  const isAliveAndActiveState =
    !bird.state.includes(enemyStates.SHOT) &&
    !bird.state.includes(enemyStates.MISSED);

  const isFlewAwayState =
    !bird.state.includes(enemyStates.SHOT) &&
    (bird.y + bird.height < 0 ||
      bird.x + bird.width < 0 ||
      bird.x > settings.canvasWidth) &&
    bird.countdown === "RUN";

  if (isAliveAndActiveState) {
    birdIsAliveAndActiveUpdates(bird, frame, settings);
  }

  if (isPainState) {
    birdIsPainStateUpdates(bird, settings);
  }

  if (isFallingState) {
    birdIsFallingUpdates(bird, frame, setLastDeadEnemyPositionX, settings);
  }

  if (isFlewAwayState) {
    birdIsFlewAwayStateUpdates(bird);
  }

  return bird;
};
