/** @format */

export const settings = {
  // canvas
  canvasWidth: 768,
  cavnasHeight: 720,

  canvasGroundLevel: 350,

  // timing
  startGameDelay: 4, // (seconds)
  betweenRoundsTime: 4, // (seconds) - minimum 3
  betweenSubRoundsTime: 4, // (seconds)
  enemySpawnDelayMinimum: 0.3, // (seconds)
  enemySpawnDelayMaximum: 0.6, // (seconds)
  onShotMessageShowTime: 50,
  backgroundMovingIntervalTime: 500, // not seconds - experiment with it :P
  backgroundMovingSpeed: 6, // not seconds - experiment with it :P
  kurreMovingIntervalTime: 500, // not seconds - experiment with it :P
  kurreMovingSpeed: 8, // not seconds - experiment with it :P

  enemyPainStateDuration: 40,

  // score
  scorePerHitNormalEnemy: 50,
  scorePerHitStressedEnemy: 100,
  scorePerDoubleHitWhereBothEnemiesAreNormal: 200,
  scorePerDoubleHitWhereOneEnemyIsStressed: 300,
  scorePerDoubleHitWhereBothEnemiesAreStressed: 500,

  scorePerCompletedRound: 1000,
  scorePerPerfectRound: 500, // example: 10/10 birds gives: scorePerCompletedRound + scorePerPerfectRound

  // enemy
  chancePercentageToSpawnStressedEnemy: 5, // (percentage)
  enemyFlyingTime: 4,

  // pass round limit
  shotEnemiesLimitToPassFirstRound: 3, // (amount)
  shotEnemiesLimitIncreaseAmount: 1, // (amount) - how much each increase is
  shotEnemiesLimitIncreaseEveryXRound: 1, // (amount) - example: x value: 2 - limit raised by y every 2nd round (3,5,7 ....)

  // speed
  enemyMainSpeed: 80,
  stressedEnemyPercentageFaster: 70, // (percentage) percentage faster than duckMainSpeed
  enemySpeedIncreasePerRound: 35, // (percentage) percentage of initial enemyMainSpeed added for each round
  enemyFallingSpeed: 4, // when shot
  enemyFallingFlickeringSpeed: 10, // flickering when falling after shot
  enemyWingFlappingSpeed: 3, // higher number = slower :P
  enemySleepingTrailGenerationSpeed: 10,
  enemySleepingTrailFadingSpeed: 90,

  // images
  enemyImages: [
    "bird-sprite-2.png",
    "bird-sprite-3.png",
    "bird-sprite-victor.png",
  ],
  enemySleepingTrailImage: "z.png",
  enemyNeutralIconImage: "duck-icon-white.png",
  enemyCurrentIconImage: "duck-icon-white.png",
  enemyShotIconImage: "duck-icon-green.png",
  enemyMissedIconImage: "duck-icon-pink.png",
  bulletImage: "bullet-icon.png",
  foregroundImage: "foreground-sheet.png",
  foregroundMobileImage: "foreground-mobile.png",
  kurreImage: "kurre-sprite.png",
  backgroundImage: "tree-and-bush-sprite.png",
  subRoundDogHoldingEnemiesImage: "detechtiv-holding.png",
  doggyImage: "doggy.png",

  // sounds
  gunshotSound:
    "detechtiv-duckhunt_src_assets_audio_8bit_gunloop_explosion.wav",
  doubleKillSound: "detechtiv-duckhunt_src_assets_audio_double-kill-sfx.mp3",

  // sound voumes
  gunShotSoundVolume: 0.2,
  doubleKillSoundVolume: 0.2,

  // text strings
  onShotMiss: "Miss",
};

export const settingsDetails = [
  // timing
  {
    timing: {
      betweenRoundsTime: {
        category: "timing",
        default: 4,
        min: 3,
        max: 10,
        input: "range",
      },
      betweenSubRoundsTime: {
        category: "timing",
        default: 4,
        min: 1,
        max: 10,
        input: "range",
      },
      onShotMessageShowTime: {
        category: "timing",
        default: 50,
        min: 0,
        max: 150,
        input: "range",
      },

      backgroundMovingIntervalTime: {
        category: "timing",
        default: 500,
        min: 50,
        max: 1000,
        input: "range",
        step: 10,
      },
      backgroundMovingSpeed: {
        category: "timing",
        default: 6,
        min: 0,
        max: 50,
        input: "range",
      },
      enemyPainStateDuration: {
        category: "timing",
        default: 40,
        min: 0,
        max: 100,
        input: "range",
        step: 10,
      },
    },
  },
  // score
  {
    score: {
      scorePerCompletedRound: {
        category: "score",
        default: 1000,
        min: 0,
        max: 5000,
        input: "range",
        step: 100,
      },
      scorePerPerfectRound: {
        category: "score",
        default: 500,
        min: 0,
        max: 5000,
        input: "range",
        step: 100,
      },
      scorePerHitNormalEnemy: {
        category: "score",
        default: 50,
        min: 0,
        max: 500,
        input: "range",
        step: 50,
      },
      scorePerHitStressedEnemy: {
        category: "score",
        default: 100,
        min: 0,
        max: 1000,
        input: "range",
        step: 50,
      },
      scorePerDoubleHitWhereBothEnemiesAreNormal: {
        category: "score",
        default: 200,
        min: 0,
        max: 1000,
        input: "range",
        step: 50,
      },
      scorePerDoubleHitWhereOneEnemyIsStressed: {
        category: "score",
        default: 300,
        min: 0,
        max: 1000,
        input: "range",
        step: 50,
      },
      scorePerDoubleHitWhereBothEnemiesAreStressed: {
        category: "score",
        default: 500,
        min: 0,
        max: 2000,
        input: "range",
        step: 50,
      },
    },
  },
  // enemy
  {
    enemy: {
      chancePercentageToSpawnStressedEnemy: {
        category: "enemy",
        default: 5,
        min: 0,
        max: 100,
        input: "range",
      },
      enemyFlyingTime: {
        category: "enemy",
        default: 4,
        min: 0,
        max: 25,
        input: "range",
      },
    },
  },
  // pass round limit
  {
    pass_round_limit: {
      shotEnemiesLimitToPassFirstRound: {
        category: "pass_round_limit",
        default: 3,
        min: 0,
        max: 10,
        input: "range",
      },
      shotEnemiesLimitIncreaseAmount: {
        category: "pass_round_limit",
        default: 1,
        min: 0,
        max: 10,
        input: "range",
      },
      shotEnemiesLimitIncreaseEveryXRound: {
        category: "pass_round_limit",
        default: 1,
        min: 0,
        max: 10,
        input: "range",
      },
    },
  },
  // speed
  {
    speed: {
      enemyMainSpeed: {
        category: "speed",
        default: 80,
        min: 0,
        max: 200,
        input: "range",
        step: 10,
      },
      stressedEnemyPercentageFaster: {
        category: "speed",
        default: 70,
        min: 0,
        max: 500,
        input: "range",
        step: 10,
      },
      enemySpeedIncreasePerRound: {
        category: "speed",
        default: 35,
        min: 1,
        max: 100,
        input: "range",
      },
      enemyFallingSpeed: {
        category: "speed",
        default: 4,
        min: 1,
        max: 20,
        input: "range",
      },
      enemyFallingFlickeringSpeed: {
        category: "speed",
        default: 10,
        min: 0,
        max: 50,
        input: "range",
      },
      enemyWingFlappingSpeed: {
        category: "speed",
        default: 3,
        min: 1,
        max: 20,
        input: "range",
      },
      enemySleepingTrailGenerationSpeed: {
        category: "speed",
        default: 10,
        min: 1,
        max: 50,
        input: "range",
      },
      enemySleepingTrailFadingSpeed: {
        category: "speed",
        default: 90,
        min: 10,
        max: 200,
        input: "range",
        step: 10,
      },
    },
  },
];

export const settingsDetails2 = {
  betweenRoundsTime: 4,

  betweenSubRoundsTime: 4,

  onShotMessageShowTime: 50,

  backgroundMovingIntervalTime: 500,

  backgroundMovingSpeed: 6,

  kurreMovingIntervalTime: 500,

  kurreMovingSpeed: 8,

  enemyPainStateDuration: 40,

  scorePerCompletedRound: 1000,

  scorePerPerfectRound: 500,

  scorePerHitNormalEnemy: 50,

  scorePerHitStressedEnemy: 100,

  scorePerDoubleHitWhereBothEnemiesAreNormal: 200,

  scorePerDoubleHitWhereOneEnemyIsStressed: 300,

  scorePerDoubleHitWhereBothEnemiesAreStressed: 500,

  chancePercentageToSpawnStressedEnemy: 5,

  enemyFlyingTime: 4,

  shotEnemiesLimitToPassFirstRound: 3,

  shotEnemiesLimitIncreaseAmount: 1,

  shotEnemiesLimitIncreaseEveryXRound: 1,

  enemyMainSpeed: 80,

  stressedEnemyPercentageFaster: 70,

  enemySpeedIncreasePerRound: 35,

  enemyFallingSpeed: 4,

  enemyFallingFlickeringSpeed: 10,

  enemyWingFlappingSpeed: 3,

  enemySleepingTrailGenerationSpeed: 10,

  enemySleepingTrailFadingSpeed: 90,
};

export const settings2 = {
  // canvas

  canvasWidth: 768,
  cavnasHeight: 720,

  canvasGroundLevel: 350,

  // timing
  startGameDelay: 4, // (seconds)

  // images
  enemyImages: [
    "bird-sprite-2.png",
    "bird-sprite-3.png",
    "bird-sprite-victor.png",
    "bird-sprite-super-victor.png",
  ],
  enemySleepingTrailImage: "z.png",
  enemyNeutralIconImage: "duck-icon-white.png",
  enemyCurrentIconImage: "duck-icon-white.png",
  enemyShotIconImage: "duck-icon-green.png",
  enemyMissedIconImage: "duck-icon-pink.png",
  bulletImage: "bullet-icon.png",
  foregroundImage: "foreground-sheet.png",
  foregroundMobileImage: "foreground-mobile.png",
  kurreImage: "kurre-sprite.png",
  backgroundImage: "tree-and-bush-sprite.png",
  subRoundDogHoldingEnemiesImage: "detechtiv-holding.png",
  doggyImage: "doggy.png",

  // sounds
  gunshotSound:
    "detechtiv-duckhunt_src_assets_audio_8bit_gunloop_explosion.wav",
  doubleKillSound: "detechtiv-duckhunt_src_assets_audio_double-kill-sfx.mp3",

  // sound voumes
  gunShotSoundVolume: 0.2,
  doubleKillSoundVolume: 0.2,

  // text strings
  onShotMiss: "Miss",
};
