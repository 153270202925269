/** @format */

import { settings } from "../../../../settings";

const enemySleepingTrailImage = require(`../../../../assets/images/${settings.enemySleepingTrailImage}`);

export const drawEnemy = (bird, canvas) => {
  const ctx = canvas.getContext("2d");
  if (bird.dontDraw) {
    return;
  }

  const zImage = new Image();
  zImage.src = enemySleepingTrailImage;

  for (let i = 0; i < bird.sleepingTrail.length; i++) {
    const z = bird.sleepingTrail[i];
    ctx.drawImage(
      z.image,
      z.xDrawingStart,
      z.yDrawingStart,
      z.spriteWidth,
      z.spriteHeight,
      z.x,
      z.y,
      z.width,
      z.height
    );
  }

  ctx.drawImage(
    bird.image,
    bird.frameX * bird.spriteWidth,
    bird.frameY * bird.spriteHeight,
    bird.spriteWidth,
    bird.spriteHeight,
    bird.x,
    bird.y,
    bird.width,
    bird.height
  );
};
