/** @format */

import { Scoreboard } from "../../components/Scoreboard";
import { views } from "../../constants";

export const MobileScoreboardView = ({ setView }) => {
  return (
    <div id="how-to-play" className="scoreboard">
      <Scoreboard />
      <button onClick={() => setView(views.HOME)}>back</button>
      <div className="dog-corner"></div>
    </div>
  );
};
