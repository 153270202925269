/** @format */

import { onShotOutcomes } from "../../../../constants";
// import { settings } from "../../../../settings";
import { enemyStates } from "../../constants";

const messageOffsetY = {
  MISS: -28,
};

export const handleOnShot = (
  event,
  settings,
  currentEnemies,
  setCurrentEnemies,
  setScore,
  canvasRef,
  setMessages
) => {
  const canvasRect = canvasRef.current.getBoundingClientRect();

  const mouseClickX = event.clientX - canvasRect.left;
  const mouseClickY = event.clientY - canvasRect.top;
  const birdsGotWrecked = currentEnemies.filter(
    (b) =>
      b.x + 10 < mouseClickX &&
      b.x - 10 + b.width > mouseClickX &&
      b.y + 10 < mouseClickY &&
      b.y + b.height - 10 > mouseClickY &&
      !b.state.includes(enemyStates.SHOT)
  );

  switch (birdsGotWrecked.length) {
    case 0:
      setMessages((prev) => [
        {
          text: settings.onShotMiss,
          countDown: settings.onShotMessageShowTime,
          positionX: mouseClickX,
          positionY: mouseClickY + messageOffsetY.MISS,
        },
        ...prev,
      ]);
      return onShotOutcomes.MISS;

    case 1:
      const birdHit = birdsGotWrecked[0];
      setCurrentEnemies(
        currentEnemies.map((bird) =>
          bird.id === birdHit.id
            ? {
                ...bird,
                state: [...bird.state, enemyStates.SHOT, enemyStates.PAINSTATE],
              }
            : bird
        )
      );

      setScore(
        (prev) =>
          (prev += birdHit.isStressedAsFuck
            ? settings.scorePerHitStressedEnemy
            : settings.scorePerHitNormalEnemy)
      );

      setMessages((prev) => [
        {
          text: `${
            birdHit.isStressedAsFuck
              ? settings.scorePerHitStressedEnemy
              : settings.scorePerHitNormalEnemy
          } pts`,
          countDown: settings.onShotMessageShowTime,
          positionX: birdHit.x + birdHit.width / 2,
          positionY: birdHit.y,
        },
        ...prev,
      ]);
      return onShotOutcomes.ONE_KILL;

    default:
      const newBirdsState = currentEnemies.map((bird) =>
        birdsGotWrecked.some((b) => bird.id === b.id)
          ? {
              ...bird,
              state: [...bird.state, enemyStates.SHOT, enemyStates.PAINSTATE],
            }
          : bird
      );
      const scoreToAdd = () => {
        const stressedDucksAmount = newBirdsState.filter(
          (b) => b.isStressedAsFuck
        ).length;
        switch (stressedDucksAmount) {
          case 1:
            return settings.scorePerDoubleHitWhereOneEnemyIsStressed;

          case 2:
            return settings.scorePerDoubleHitWhereBothEnemiesAreStressed;

          default:
            return settings.scorePerDoubleHitWhereBothEnemiesAreNormal;
        }
      };
      setScore((prev) => (prev += scoreToAdd()));
      setCurrentEnemies(newBirdsState);

      const birdToSetMessageTo = birdsGotWrecked.sort((a, b) => a.y > b.y)[0];

      const newMessage = {
        text: `${scoreToAdd()} pts`,
        countDown: settings.onShotMessageShowTime,
        positionX: birdToSetMessageTo.x + birdToSetMessageTo.width / 2,
        positionY: birdToSetMessageTo.y,
      };
      setMessages((prev) => [newMessage, ...prev]);
      return onShotOutcomes.DOUBLE_KILL;
  }

  // if (!birdsGotWrecked.length) {
  //   const newMessage = {
  //     text: "Miss",
  //     countDown: 50,
  //     positionX: mouseClickX,
  //     positionY: mouseClickY + messageOffsetY.MISS,
  //   };
  //   setMessages((prev) => [newMessage, ...prev]);
  //   return;
  // }

  // if (birdsGotWrecked.length === 1) {
  //   const birdHit = birdsGotWrecked[0];

  //   const newBirdsState = currentEnemies.map((bird) =>
  //     bird.id === birdHit.id
  //       ? {
  //           ...bird,
  //           state: [...bird.state, enemyStates.SHOT, enemyStates.PAINSTATE],
  //         }
  //       : bird
  //   );
  //   setCurrentEnemies(newBirdsState);

  //   setScore(
  //     (prev) =>
  //       (prev += birdHit.isStressedAsFuck
  //         ? settings.scorePerHitStressedEnemy
  //         : settings.scorePerHitNormalEnemy)
  //   );

  //   const newMessage = {
  //     text: `${
  //       birdHit.isStressedAsFuck
  //         ? settings.scorePerHitStressedEnemy
  //         : settings.scorePerHitNormalEnemy
  //     }p`,
  //     countDown: 50,
  //     positionX: birdHit.x + birdHit.width / 2,
  //     positionY: birdHit.y,
  //   };
  //   setMessages((prev) => [newMessage, ...prev]);
  // }

  // if (birdsGotWrecked.length === 2) {
  //   const newBirdsState = currentEnemies.map((bird) =>
  //     birdsGotWrecked.some((b) => bird.id === b.id)
  //       ? {
  //           ...bird,
  //           state: [...bird.state, enemyStates.SHOT, enemyStates.PAINSTATE],
  //         }
  //       : bird
  //   );
  //   const scoreToAdd = () => {
  //     const stressedDucksAmount = newBirdsState.filter(
  //       (b) => b.isStressedAsFuck
  //     ).length;
  //     switch (stressedDucksAmount) {
  //       case 1:
  //         return settings.scorePerDoubleHitWhereOneEnemyIsStressed;

  //       case 2:
  //         return settings.scorePerDoubleHitWhereBothEnemiesAreStressed;

  //       default:
  //         return settings.scorePerDoubleHitWhereBothEnemiesAreNormal;
  //     }
  //   };
  //   setScore((prev) => (prev += scoreToAdd()));
  //   setCurrentEnemies(newBirdsState);

  //   const birdToSetMessageTo = birdsGotWrecked.sort((a, b) => a.y > b.y)[0];

  //   const newMessage = {
  //     text: `${scoreToAdd()}p`,
  //     countDown: 50,
  //     positionX: birdToSetMessageTo.x + birdToSetMessageTo.width / 2,
  //     positionY: birdToSetMessageTo.y,
  //   };
  //   setMessages((prev) => [newMessage, ...prev]);
  // }
};
