/** @format */

import { useContext, useEffect, useState } from "react";
import { settings as settings2 } from "../../../settings";
import { If } from "../../../helpers/components";
import { SettingsContext } from "../../../App";

const subRoundDogHoldingEnemiesImage = require(`../../../assets/images/${settings2.subRoundDogHoldingEnemiesImage}`);

export const BetweenSubRoundsCutScene = ({
  cutScene,
  lastDeadEnemyPositionX,
  canvasRef,
}) => {
  const settings = useContext(SettingsContext);
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(3);
    }, settings.betweenSubRoundsTime * 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!counter) return;

    if (counter > 1) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  // useEffect(() => {
  //   if (canvasRef && canvasRef.current) {
  //     const ctx = canvasRef.current.getContext("2d");
  //   }
  // }, []);

  return (
    <>
      <If condition={cutScene.type === "subRound"}>
        <h1>hello</h1>
      </If>
    </>
  );
};
