/** @format */

import { useContext, useEffect, useState } from "react";
import { If } from "../../../helpers/components";
import { SettingsContext } from "../../../App";

export const BetweenRoundsCutScene = ({ cutScene }) => {
  const settings = useContext(SettingsContext);
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(3);
    }, settings.betweenRoundsTime * 1000 - 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!counter) return;

    if (counter > 1) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  return (
    <>
      <If condition={cutScene.type === "round"}>
        <section id="between-rounds-scoreboard" style={{ width: "80%" }}>
          <div className="between-rounds-scoreboard__score-item">
            completed round: {settings.scorePerCompletedRound}
          </div>
          <If condition={cutScene.isPerfectRound}>
            <div className="between-rounds-scoreboard__score-item">
              perfect round: {settings.scorePerPerfectRound}
            </div>
          </If>

          <div className="between-rounds-scoreboard__next-round">
            round {cutScene.nextRound} coming up
            <If condition={counter}>
              <div>... in {counter}</div>
            </If>
          </div>
        </section>
      </If>
    </>
  );
};
