/** @format */

import { enemyStates } from "../constants";

export const generateNewSubRound = (currentRound) => {
  const newCurrentEnemies = [];
  for (let i = 0; i < currentRound.enemies.length; i++) {
    const thisEnemy = currentRound.enemies[i];
    const isNewCurrentEnemy =
      newCurrentEnemies.filter((e) => e.state.includes(enemyStates.CURRENT))
        .length !== 2 && thisEnemy.state.includes(enemyStates.NEUTRAL);

    if (isNewCurrentEnemy) {
      newCurrentEnemies.push({
        ...thisEnemy,
        state: [enemyStates.CURRENT],
      });
    } else {
      newCurrentEnemies.push({
        ...thisEnemy,
        state: thisEnemy.state.filter((s) => s !== enemyStates.CURRENT),
      });
    }
  }

  return {
    ...currentRound,
    enemies: newCurrentEnemies,
    bullets: 3,
  };
};
