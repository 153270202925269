/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GET, POST } from "../utils";
import { endpoints } from "../../constants";

export const useGetAllScores = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["getAllScores", useGetAllScores.name],
    queryFn: async () => await GET(endpoints().GET_SCOREBOARD),
  });

  return {
    data,
    isLoading,
    isError,
  };
};

export const useCreateScore = (postData) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, _data } = useMutation(
    async () => await POST(endpoints().CREATE_SCORE, postData),
    {
      onSuccess: () => {
        const message = "success";
        alert(message);
      },
      onError: () => {
        alert("there was an error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("create");
      },
    }
  );

  return {
    mutate,
    isLoading,
  };
};
