/** @format */

export const views = {
  HOME: 1,
  HOW_TO_PLAY: 2,
  GAME: 3,
  GAME_OVER: 4,
  CUTSCENE_START: 5,
  SETTINGS: 6,
  CUTSCENE_GAME_OVER: 7,
  MOBILE_SCOREBOARD: 8,
  COMIC: 9,
};

export const onShotOutcomes = {
  MISS: 1,
  ONE_KILL: 2,
  DOUBLE_KILL: 3,
};

export const endpoints = () => ({
  GET_ALL_SCORES: "/get_all_scores",
  GET_SCOREBOARD: "/get_top_ten_scores",
  CREATE_SCORE: "/create_score",
  AUTH: "/auth",
});
