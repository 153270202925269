/** @format */

import { createContext, useEffect, useState } from "react";
import "./style/main.scss";
import { views } from "./constants";
import { If } from "./helpers/components";
import {
  CutsceneStart,
  Game,
  GameOver,
  Home,
  HowToPlay,
  CutsceneGameOver,
  ComicView,
} from "./views";
import { Scoreboard } from "./components/Scoreboard";
import { Settings } from "./components/Settings";
import { settingsDetails, settings2 } from "./settings";
import { useMediaQuery } from "react-responsive";
import { MobileScoreboardView } from "./views/mobile-scoreboard/MobileScoreboardView";
import ReactGA from "react-ga4";

ReactGA.initialize("G-KZPKR72NDP");

function transformSettings(details) {
  const transformedSettings = {};

  details.forEach((setting) => {
    for (const key in setting) {
      for (const subKey in setting[key]) {
        if (setting[key][subKey].category === key) {
          transformedSettings[subKey] =
            setting[key][subKey].newValue || setting[key][subKey].default;
        }
      }
    }
  });

  return transformedSettings;
}

export const SettingsContext = createContext(null);
export const IsMobileContext = createContext(null);

export const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [view, setView] = useState(views.HOME);
  const [theSettings, setTheSettings] = useState(
    (localStorage.getItem("settings") &&
      JSON.parse(localStorage.getItem("settings"))) ||
      settingsDetails
  );

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const showScoreboard = useMediaQuery({
    query: "(min-width: 1230px)",
  });

  const sett = isMobile
    ? {
        ...settings2,
        canvasWidth: windowWidth,
      }
    : settings2;

  const testV = (localStorage.getItem("settings") && {
    ...transformSettings(JSON.parse(localStorage.getItem("settings"))),
    ...sett,
  }) || {
    ...transformSettings(theSettings),
    ...sett,
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`App ${isMobile ? "mobile" : ""}`}>
      <SettingsContext.Provider value={testV}>
        <IsMobileContext.Provider value={isMobile}>
          <If condition={view === views.HOME}>
            <Home setView={setView} />
          </If>

          <If condition={view === views.HOW_TO_PLAY}>
            <HowToPlay setView={setView} />
          </If>

          <If condition={view === views.GAME}>
            <Game setView={setView} />
          </If>

          <If condition={view === views.GAME_OVER}>
            <GameOver setView={setView} />
          </If>

          <If condition={view === views.CUTSCENE_START}>
            <CutsceneStart setView={setView} />
          </If>

          <If condition={view === views.MOBILE_SCOREBOARD}>
            <MobileScoreboardView setView={setView} />
          </If>

          <If condition={view === views.SETTINGS}>
            <Settings
              theSettings={theSettings}
              setTheSettings={setTheSettings}
              setView={setView}
            />
          </If>

          <If condition={view === views.CUTSCENE_GAME_OVER}>
            <CutsceneGameOver setView={setView} />
          </If>

          <If condition={view === views.COMIC}>
            <ComicView setView={setView} />
          </If>

          <If condition={view !== views.SETTINGS && showScoreboard}>
            <Scoreboard />
          </If>
        </IsMobileContext.Provider>
      </SettingsContext.Provider>
    </div>
  );
};
