/** @format */

import { views } from "../../constants";

export const CutsceneStart = ({ setView }) => {
  return (
    <>
      <div id="how-to-play" className="cutscene-start">
        
        <h3>Use the tranquilliser to shoot the missing recruiters!</h3>
      </div>
    </>
  );
};
