/** @format */

import { settings as settings2 } from "../../../settings";
import { enemyStates } from "../constants";

// const enemyImage = require(`../../../assets/images/${settings2.enemyImage}`);

const getSpawnDelay = (min, max) => {
  return ((Math.random() * (max - min + 1) + min) * 1000).toFixed(3);
  // return Math.floor(Math.random() * (max - min + 1)) + min;
};

const generateEnemyDirectionXandY = (round, settings) => {
  const difficultyMultiplier =
    (settings.enemySpeedIncreasePerRound / 100) * round;

  const speed = (settings.enemyMainSpeed * 0.1 + difficultyMultiplier).toFixed(
    2
  );

  const randomNr1 = Math.random() * speed;
  const randomNr2 = speed - randomNr1;

  const directionX = randomNr1.toFixed(3);
  const directionY = randomNr2.toFixed(3);

  const initialDirectionX = Math.random() < 0.5;

  return [initialDirectionX ? directionX : -directionX, directionY];
};

function getPositiveSum(num1, num2) {
  if (num1 < 0) {
    num1 = Math.abs(num1);
  }
  if (num2 < 0) {
    num2 = Math.abs(num2);
  }
  return num1 + num2;
}

export const generateNewEnemyDirectionXandY = (enemy, bumpSide) => {
  const currentSpeed = getPositiveSum(
    parseFloat(enemy.directionX),
    parseFloat(enemy.directionY)
  ).toFixed(2);

  const randomNr1 = Math.random() * currentSpeed;
  const randomNr2 = currentSpeed - randomNr1;

  const newDirectionX = randomNr1.toFixed(3);
  const newDirectionY = randomNr2.toFixed(3);

  switch (bumpSide) {
    case "left":
      const goUpLeft = Math.random() < 0.5;
      return {
        x: newDirectionX,
        y: goUpLeft ? newDirectionY : -newDirectionY,
      };

    case "right":
      const goUpRight = Math.random() < 0.5;
      return {
        x: -newDirectionX,
        y: goUpRight ? newDirectionY : -newDirectionY,
      };

    case "top":
      const goRightTop = Math.random() < 0.5;
      return {
        x: goRightTop ? newDirectionX : -newDirectionX,
        y: -newDirectionY,
      };

    default:
      const goRightBot = Math.random() < 0.5;
      return {
        x: goRightBot ? newDirectionX : -newDirectionX,
        y: newDirectionY,
      };
  }
};

const createEnemy = (id, round, settings) => {
  const enemyImages = settings.enemyImages;
  const randomImageIndex = Math.floor(Math.random() * enemyImages.length);
  const randomEnemyImage = enemyImages[randomImageIndex];

  const image = new Image();
  image.src = require(`../../../assets/images/${randomEnemyImage}`);
  const birdWidth = 108;
  const birdHeight = 108;
  const [directionX, directionY] = generateEnemyDirectionXandY(round, settings);
  const spawnStressadEnemy =
    Math.random() <
    (settings.chancePercentageToSpawnStressedEnemy / 100).toFixed(3);
  const enemyStateShouldBeCurrent = id === 0 || id === 1;

  return {
    id: id,
    spriteWidth: birdWidth,
    spriteHeight: birdHeight,
    width: birdWidth,
    height: birdHeight,
    x: Math.random() * (settings.canvasWidth - birdWidth),
    y: settings.cavnasHeight - 350,
    directionX: -directionX,
    directionY: directionY,
    isStressedAsFuck: spawnStressadEnemy,
    image: image,
    frameX: 0,
    frameY: 0,
    countdown: false,
    painStateDurationState: false,
    dontDraw: false,
    sleepingTrail: [],
    spawnDelay: getSpawnDelay(
      settings.enemySpawnDelayMinimum,
      settings.enemySpawnDelayMaximum
    ),
    state: enemyStateShouldBeCurrent
      ? [enemyStates.CURRENT]
      : [enemyStates.NEUTRAL],
  };
};

export const generateNewRound = (currentRound, settings) => {
  const round = currentRound.round + 1;

  const enemies = [];
  for (let i = 0; i < 10; i++) {
    enemies.push(createEnemy(i, round, settings));
  }

  const timeToIncreasePassLimit =
    (round - 1) % settings.shotEnemiesLimitIncreaseEveryXRound === 0;

  const roundPassLimit =
    timeToIncreasePassLimit && round !== 1
      ? currentRound.roundPassLimit + 1
      : currentRound.roundPassLimit;

  const limit = roundPassLimit > 9 ? 10 : roundPassLimit;

  return {
    round: round,
    enemies: enemies,
    roundPassLimit: limit,
    bullets: 3,
  };
};
