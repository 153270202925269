/** @format */

const crosshairSize = 40; // Size of the crosshair
const circleRadius = 10; // Radius of the circle

export const drawCrosshair = (canvas, mousePosition) => {
  const ctx = canvas.getContext("2d");

  ctx.beginPath();
  ctx.arc(
    mousePosition.x,
    mousePosition.y,
    circleRadius * 1.5,
    0,
    2 * Math.PI,
    false
  );
  ctx.strokeStyle = "black";
  ctx.lineWidth = 2;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(mousePosition.x, mousePosition.y - crosshairSize / 2);
  ctx.lineTo(mousePosition.x, mousePosition.y - circleRadius);
  ctx.strokeStyle = "black";
  ctx.lineWidth = 2;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(mousePosition.x - crosshairSize / 2, mousePosition.y);
  ctx.lineTo(mousePosition.x - circleRadius, mousePosition.y);
  ctx.strokeStyle = "black";
  ctx.lineWidth = 2;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(mousePosition.x + circleRadius, mousePosition.y);
  ctx.lineTo(mousePosition.x + crosshairSize / 2, mousePosition.y);
  ctx.strokeStyle = "black";
  ctx.lineWidth = 2;
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(mousePosition.x, mousePosition.y + circleRadius);
  ctx.lineTo(mousePosition.x, mousePosition.y + crosshairSize / 2);
  ctx.strokeStyle = "black";
  ctx.lineWidth = 2;
  ctx.stroke();
};
