/** @format */

import { useEffect, useRef, useState } from "react";
import { endpoints, views } from "../../constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET, POST } from "../../helpers/utils";

export const GameOver = ({ setView }) => {
  const score = localStorage.getItem("score");
  const emailInput = useRef(null);
  const nameInput = useRef(null);
  const phoneInput = useRef(null);

  const [nameInputError, setNameInputError] = useState(null);
  const [emailInputError, setEmailInputError] = useState(null);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      return await POST(
        endpoints().CREATE_SCORE,
        payload,
        localStorage.getItem("auth")
      );
    },
    {
      onSuccess: () => {
        localStorage.removeItem("score");
        localStorage.removeItem("auth");
        setView(views.HOME);
      },
      onError: () => {
        alert("there was an error");
      },
      onSettled: () => {
        queryClient.invalidateQueries("create");
      },
    }
  );

  const validInputs = () => {
    const validEmail = String(emailInput.current?.value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    const validName = !!nameInput.current?.value;

    if (!validEmail || !validName) {
      if (!validEmail) {
        setEmailInputError("Not Valid");
      } else {
        setEmailInputError(null);
      }
      if (!validName) {
        setNameInputError("Not Valid");
      } else {
        setNameInputError(null);
      }
      return false;
    }

    setEmailInputError(null);
    setNameInputError(null);
    return true;
  };

  useEffect(() => {
    if (!localStorage.getItem("auth")) {
      GET(endpoints().AUTH).then((res) =>
        localStorage.setItem("auth", res.toString())
      );
    }
  }, []);

  if (isLoading) return <>loading</>;

  return (
    <>
      <div id="game-over">
        <h2 className="game-over-heading">gameover</h2>
        <h3> You scored: <br />{score || ""}</h3>
        <p>
        Ready to make your mark in the high score? Enter your email and name below to join the ranks of top hunters.
        </p>
        <div>
          <label>namn:</label>
          <input type="text" ref={nameInput} />
          <div>{nameInputError}</div>
        </div>
        <div>
          <label>email:</label>
          <input type="email" ref={emailInput} />
          <div>{emailInputError}</div>
        </div>
        <div>
          <label>phone (optional):</label>
          <input type="number" ref={phoneInput} />
        </div>
        <div className="buttons-game-over">
        <button
          onClick={() => {
            if (!validInputs()) {
              return;
            }
            const payload = {
              email: emailInput.current.value,
              name: nameInput.current.value,
              score: score,
            };
            if (phoneInput.current.value) {
              payload.phone = phoneInput.current.value;
            }
            mutate(payload);
          }}
        >
          submit score
        </button>
        <button
          onClick={() => {
            localStorage.removeItem("score");
            localStorage.removeItem("auth");
            setView(views.GAME);
          }}
        >
          PLAY AGAIN
        </button>
        </div>
        <div className="back-to-home">
        <a
          onClick={() => {
            localStorage.removeItem("score");
            localStorage.removeItem("auth");
            setView(views.HOME);
          }}
        >
          BACK TO HOME
        </a>
        </div>
      </div>
    </>
  );
};
