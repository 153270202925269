/** @format */

import { useMemo } from "react";
import { settings as settings2 } from "../../../settings";
import { enemyStates } from "../constants";

const enemyNeutralIconImage = require(`../../../assets/images/${settings2.enemyNeutralIconImage}`);
const enemyCurrentIconImage = require(`../../../assets/images/${settings2.enemyCurrentIconImage}`);
const enemyShotIconImage = require(`../../../assets/images/${settings2.enemyShotIconImage}`);
const enemyMissedIconImage = require(`../../../assets/images/${settings2.enemyMissedIconImage}`);

export const EnemyIcons = ({ enemiesStates: ES, roundPassLimit }) => {
  const enemiesStates = useMemo(() => ES.map((enemy) => enemy.state), [ES]);

  return (
    <>
      <div id="hit-counter">
        <div id="hit-counter__enemies">
          <h2>HIT</h2>
          <ul>
            {enemiesStates.map((state, i) => {
              const icon = () => {
                if (state.includes(enemyStates.SHOT)) {
                  return enemyShotIconImage;
                }
                if (state.includes(enemyStates.MISSED)) {
                  return enemyMissedIconImage;
                }
                if (state.includes(enemyStates.CURRENT)) {
                  return enemyCurrentIconImage;
                }
                return enemyNeutralIconImage;
              };
              return (
                <li key={i}>
                  <img src={icon()} width="21px" height="24px" />
                </li>
              );
            })}
          </ul>
        </div>
        <p id="hit-counter__pass-limit">Pass limit: {roundPassLimit}</p>
      </div>
    </>
  );
};
