/** @format */

import { views } from "../../constants";

export const HowToPlay = ({ setView }) => {
  return (
    <>
      <div id="how-to-play">
        <h3>how to play</h3>
        <p>
          The recruiters have vanished! It's up to the DETECHTIV HUNTER to track
          them down, and he's counting on your assistance. Will you join forces
          to capture the elusive RECRUITERS and restore innovation across the
          land?
        </p>
        <p>
          Armed with a TRANQUALIZER, use your mouse to aim and shoot at the
          RECRUITERS. The DETECHTIV HUNTER will then retrieve them and return
          them to their rightful office.
        </p>
        <p>
          Beware! Some of the recruiters are feeling the pressure. They haven't
          had a chance to congratulate a developer on their LinkedIn profile in
          days. Stressed employees give extra points! Aim for a double hit to
          get even more extra points!
        </p>
        <p>
          With each passing round, you'll need to capture more RECRUITERS.
          Successfully apprehend them to progress to the next level.
        </p>
        <button onClick={() => setView(views.HOME)}>back</button>
        <div className="dog-corner">
          
        </div>
      </div>
    </>
  );
};
