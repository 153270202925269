/** @format */

import { views } from "../../constants";

export const CutsceneGameOver = ({ setView }) => {
  return (
    <>
      <div id="how-to-play" className="cutscene-game-over">
        <h2>GAME OVER</h2>
        <h3>"Great job! Some of the RECRUITERS managed to elude us this time, the quest to capture them is far from over.</h3>
        <h4>Why not give it another shot? The DETECHTIV HUNTER is relying on you. Will you answer the call and ensure innovation blooms throughout the land?</h4>
        <button
          onClick={() => {
            setView(views.GAME_OVER);
          }}
        >
          NEXT
        </button>
      </div>
    </>
  );
};
