/** @format */

import { views } from "../../constants";

export const ComicView = ({ setView }) => {
  return (
    <>
      <div id="how-to-play" className="comic-view">
        <ul>
          <li>
            <img src="./images/comic/Cover.jpg" alt="" />
          </li>
          <li>
            <img src="./images/comic/P1.jpg" alt="" />
          </li>
          <li>
            <img src="./images/comic/P2.jpg" alt="" />
          </li>
          <li>
            <img src="./images/comic/P3.jpg" alt="" />
          </li>
          <li>
            <img src="./images/comic/P4.jpg" alt="" />
          </li>
          <li>
            <img src="./images/comic/P5.jpg" alt="" />
          </li>
          <li>
            <img src="./images/comic/P6.jpg" alt="" />
          </li>
          <li>
            <img src="./images/comic/P7.jpg" alt="" />
          </li>
        </ul>
        <div className="close">
          <a
            href
            onClick={() => {
              localStorage.removeItem("score");
              localStorage.removeItem("auth");
              setView(views.HOME);
            }}
          >
            close
          </a>
        </div>
      </div>
    </>
  );
};
