/** @format */

import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { settings as settings2 } from "../../settings";
import { generateNewRound, generateNewSubRound } from "./utils";
import { enemyStates } from "./constants";
import { If } from "../../helpers/components";
import { views } from "../../constants";
import { EnemyIcons, NoBulletText } from "./components";
import { Canvas } from "./canvas";
import { SettingsContext } from "../../App";

const bulletImage = require(`../../assets/images/${settings2.bulletImage}`);

// const initialRoundValue = {
//   round: 0,
//   enemies: [],
//   roundPassLimit: settings.shotEnemiesLimitToPassFirstRound,
//   bullets: 3,
// };

export const Game = ({ setView }) => {
  const settings = useContext(SettingsContext);

  const initialRoundValue = useMemo(
    () => ({
      round: 0,
      enemies: [],
      roundPassLimit: settings.shotEnemiesLimitToPassFirstRound,
      bullets: 3,
    }),
    []
  );

  const [score, setScore] = useState(0);
  const [round, setRound] = useState(() =>
    generateNewRound(initialRoundValue, settings)
  );
  const [cutScene, setCutScene] = useState(false);

  const currentEnemies = useMemo(
    () =>
      round.enemies.filter((enemy) =>
        enemy.state.includes(enemyStates.CURRENT)
      ),
    [round.enemies]
  );

  const handleGenerateNewRound = useCallback(() => {
    const isCompleteRound =
      round.enemies.filter((enemy) => enemy.state.includes(enemyStates.SHOT))
        .length >= round.roundPassLimit;

    if (!isCompleteRound) {
      localStorage.setItem("score", score.toString());
      return setView(views.CUTSCENE_GAME_OVER);
    }

    const isPerfectRound = round.enemies.every((enemy) =>
      enemy.state.includes(enemyStates.SHOT)
    );

    const scoreToAdd = isPerfectRound
      ? settings.scorePerCompletedRound + settings.scorePerPerfectRound
      : settings.scorePerCompletedRound;

    setScore((prev) => prev + scoreToAdd);
    setCutScene({
      type: "round",
      isCompleteRound: isCompleteRound,
      isPerfectRound: isPerfectRound,
      nextRound: round.round + 1,
    });

    setTimeout(() => {
      setRound((prev) => generateNewRound(prev, settings));
      setCutScene(false);
    }, settings.betweenRoundsTime * 1000);
  }, [round]);

  const handleGenerateNewSubRound = useCallback(() => {
    setCutScene({
      type: "subRound",
    });

    setTimeout(() => {
      setRound((prev) => generateNewSubRound(prev));
      setCutScene(false);
    }, settings.betweenSubRoundsTime * 1000);
  }, []);

  const handleSetCurrentEnemies = useCallback(
    (newEnemiesState) =>
      setRound((prev) => ({
        ...prev,
        enemies: prev.enemies.map(
          (enemy) => newEnemiesState.find((e) => e.id === enemy.id) ?? enemy
        ),
      })),
    []
  );

  useEffect(() => {
    const isNewRound = round.enemies.every((b) => b.dontDraw);
    const isNewSubRound =
      currentEnemies.length === 2 && currentEnemies.every((b) => b.dontDraw);

    if (isNewRound) {
      return handleGenerateNewRound();
    }

    if (isNewSubRound) {
      return handleGenerateNewSubRound();
    }
  }, [currentEnemies]);

  return (
    <>
      <div
        id="canvas-container"
        style={{ width: settings.canvasWidth, height: settings.cavnasHeight }}
        onClick={() => {
          if (round.bullets > 0 && !cutScene) {
            setRound((prev) => ({ ...prev, bullets: prev.bullets - 1 }));
          }
        }}
      >
        <If
          condition={
            round.bullets === 0 &&
            !cutScene &&
            currentEnemies.some(
              (enemy) => !enemy.state.includes(enemyStates.SHOT)
            )
          }
        >
          <NoBulletText />
        </If>
        <Canvas
          cutScene={cutScene}
          bullets={round.bullets}
          setScore={setScore}
          currentEnemies={currentEnemies}
          setCurrentEnemies={handleSetCurrentEnemies}
        />

        <div id="bullets-container">
          <ul id="bullets">
            {Array.from({ length: round.bullets }).map((_, index) => (
              <li key={index}>
                <img src={bulletImage} />
              </li>
            ))}
          </ul>
          <h2>AMMO</h2>
        </div>

        <EnemyIcons
          // enemiesStates={round.enemies
          //   .map((enemy) => enemy.state)
          //   .sort((a, b) => b.length > 1 - a.length < 2)}
          enemiesStates={round.enemies}
          roundPassLimit={round.roundPassLimit}
        />

        <div id="round-counter">R={round.round}</div>

        <div id="score-counter">
          <h2 id="score-value">{score.toString().padStart(6, "0")}</h2>
          <h2>SCORE</h2>
        </div>
      </div>
    </>
  );
};
