/** @format */

import { useEffect, useState } from "react";
import { useGetAllScores } from "../helpers/hooks";

const ss = [{ name: "dd", email: "jsjs", score: 10 }];

export const Scoreboard = () => {
  const { data: scores, isLoading } = useGetAllScores();
  const [scorens, setScorens] = useState([]);

  useEffect(() => {
    const arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push(scores?.data[i] ?? { name: "...", score: "" });
    }

    setScorens(arr);
  }, [scores]);

  return (
    <div className="scoreboard">
      <div className="logo">
          <h2 className="title">Detechtiv</h2>
          <div className="divider"></div>
          <h2 className="title">Hunt</h2>
        </div>
      <h3>Scoreboard</h3>
      <ul id="scoreB">
        {scorens.map((d, i) => {
          return (
            <li>
              <span className="score-name">
                {i + 1}. {d.name}{" "}
              </span>
              <span>{d.score}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
