/** @format */

import { useState } from "react";
import { settings, settingsDetails } from "../settings";
import { views } from "../constants";

export const exludeKeys = [
  "canvasWidth",
  "cavnasHeight",
  "canvasGroundLevel",
  "startGameDelay",
  "enemyImage",
  "enemySleepingTrailImage",
  "enemyNeutralIconImage",
  "enemyCurrentIconImage",
  "enemyShotIconImage",
  "enemyMissedIconImage",
  "bulletImage",
  "foregroundImage",
  "kurreImage",
  "backgroundImage",
  "subRoundDogHoldingEnemiesImage",
  "gunshotSound",
  "doubleKillSound",
];

export const Input = ({ inputKey, value, setTheSettings }) => {
  const handleSetSettings = (e) =>
    setTheSettings((prev) => {
      const [titel, _values] = Object.entries(prev)[0];
      const newOne = prev.find((d) => d[`${value.category}`]);
      const newOneField = newOne[value.category];
      const test = newOneField[inputKey];
      test["newValue"] = Number(e.target.value);

      return prev.map((p) => (titel !== value.category ? p : { ...p, newOne }));
    });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        {inputKey}: {value.newValue || value.default}
      </div>
      <input
        style={{ width: "100%" }}
        type={value.input}
        name={value.key}
        min={value.min}
        max={value.max}
        step={value.step || 1}
        value={value.newValue || value.default}
        onChange={handleSetSettings}
      />
    </div>
  );
};

export const Settings = ({ setTheSettings, theSettings, setView }) => {
  return (
    <section
      className="page-settings"
      style={{
        border: "2px solid white",
        width: "75vw",
        height: "100vh",
        padding: "20px",
        overflow: "scroll",
      }}
    >
      <button
        onClick={() => {
          localStorage.setItem("settings", JSON.stringify(theSettings));
          setView(views.HOME);
        }}
      >
        SAVE
      </button>

      <button
        onClick={() => {
          localStorage.setItem("settings", JSON.stringify(settingsDetails));
          setTheSettings(settingsDetails);
          setView(views.HOME);
        }}
      >
        Reset to default values
      </button>
      {theSettings.map((s) => {
        const [titel, values] = Object.entries(s)[0];
        return (
          <>
            <div style={{ fontSize: "40px" }}>{titel}</div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {Object.entries(values)
                .filter(([key]) => !exludeKeys.includes(key))
                .map(([key, value]) => (
                  <Input
                    key={key}
                    value={value}
                    inputKey={key}
                    setTheSettings={setTheSettings}
                  />
                ))}
            </div>
            <br />
            <br />
          </>
        );
      })}
    </section>
  );
};
